import { max, min } from 'lodash';
import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const huanan_day_range_5 = createIndicator({
    id: 'huanandayrange5',
    displayName: '期間高低',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const symbol = this.PineJS.Std.ticker(this._context);
            const originalTX = symbol.includes('AM');
            const length = this._input(0);
            const high = this.ohlc.high;
            const low = this.ohlc.low;
            const high_array = this.ohlc.highArray;
            const low_array = this.ohlc.lowArray;
            const itime = this.PineJS.Std.time(this._context);
            const date = new Date(itime).getDate();
            const date_array = this._context.new_var();
            const hours = new Date(itime).getHours();
            const minutes = new Date(itime).getMinutes();
            const openTime = hours === 8 && minutes === 45;
            const tradeTime = !openTime && hours >= 8 && hours < 15;
            const daily_high = this._context.new_var();
            const daily_low = this._context.new_var();
            const day_high = this._context.new_var();
            const day_low = this._context.new_var();
            const rangeHigh = this._context.new_var();
            const rangeLow = this._context.new_var();
            date_array.set(date);
            date_array.get(1);
            day_high.get(100);
            day_low.get(100);
            if (!originalTX) {
                //換日重置日高日低數值
                if (openTime) {
                    daily_high.set(high);
                    daily_low.set(low);
                }
                if (tradeTime) {
                    //記錄盤中至收盤的最高點 更新日高
                    if (high > daily_high) {
                        daily_high.set(high);
                    }
                    //記錄盤中至收盤的最低點 更新日低
                    if (low < daily_low) {
                        daily_low.set(low);
                    }
                }
                if (hours === 15 && minutes === 0) {
                    //收盤確認每日高低數值
                    day_high.set(daily_high.get(0));
                    day_low.set(daily_low.get(0));
                    const hh = [day_high.get(0)];
                    const ll = [day_low.get(0)];
                    //根據指定的區間天數給予n天前的每日高低值
                    for (let i = 0; i < length; i++) {
                        hh.push(day_high.get(i));
                        ll.push(day_low.get(i));
                    }
                    //比較且給予最終要畫的線數值
                    rangeHigh.set(max(hh) ?? NaN);
                    rangeLow.set(min(ll) ?? NaN);
                }
                return [rangeHigh.get(0), rangeLow.get(0)];
            }
            else {
                if (openTime) {
                    //收盤確認每日高低數值
                    day_high.set(daily_high.get(0));
                    day_low.set(daily_low.get(0));
                    const hh = [day_high.get(0)];
                    const ll = [day_low.get(0)];
                    //根據指定的區間天數給予n天前的每日高低值
                    for (let i = 0; i < length; i++) {
                        hh.push(day_high.get(i));
                        ll.push(day_low.get(i));
                    }
                    //比較且給予最終要畫的線數值
                    rangeHigh.set(max(hh) ?? NaN);
                    rangeLow.set(min(ll) ?? NaN);
                    //換日重置高低數值
                    daily_high.set(high);
                    daily_low.set(low);
                }
                if (tradeTime) {
                    //記錄盤中至收盤的最高點
                    if (high > daily_high) {
                        daily_high.set(high);
                    }
                    //記錄盤中至收盤的最低點
                    if (low < daily_low) {
                        daily_low.set(low);
                    }
                }
                return [rangeHigh.get(0), rangeLow.get(0)];
            }
        },
    },
    metainfo: {
        is_price_study: !0,
        _metainfoVersion: 42,
        isTVScript: !1,
        isTVScriptStub: !1,
        is_hidden_study: !1,
        plots: [
            { id: 'plot_0', type: 'line' },
            { id: 'plot_1', type: 'line' },
        ],
        defaults: {
            styles: {
                plot_0: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 3,
                    trackPrice: !1,
                    transparency: 0,
                    visible: false,
                    color: '#d75442',
                },
                plot_1: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 3,
                    trackPrice: !1,
                    transparency: 0,
                    visible: false,
                    color: '#54d742',
                },
            },
            precision: 2,
            inputs: { in_0: 20 },
        },
        styles: {
            plot_0: {
                title: '指標',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_1: {
                title: '指標',
                histogramBase: 0,
                joinPoints: !1,
            },
        },
        inputs: [
            {
                id: 'in_0',
                name: '期間',
                defval: 20,
                type: 'integer',
                min: 1,
                max: 20,
                step: 1,
            },
        ],
    },
});
