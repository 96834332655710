import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const huanan_ColoredMovAvg = createIndicator({
    id: 'huanan-ColoredMovAvg',
    displayName: 'huanan_ColoredMovAvg',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const length = this._input(0);
            const close = this.ohlc.closeArray;
            const smaValue = this.average(close, length);
            const sma = this._context.new_var(smaValue);
            const lineColor = sma.get(0) > sma.get(1) ? 0 : 1;
            return [sma.get(0), lineColor];
        },
    },
    metainfo: {
        is_price_study: true,
        _metainfoVersion: 42,
        isTVScript: false,
        isTVScriptStub: false,
        is_hidden_study: false,
        plots: [
            { id: 'plot0', type: 'line' },
            {
                id: 'smaPalette',
                type: 'colorer',
                target: 'plot0',
                palette: 'smaPalette',
            },
        ],
        palettes: {
            smaPalette: {
                colors: {
                    0: {
                        name: 'UpTrend',
                    },
                    1: {
                        name: 'DownTrend',
                    },
                },
            },
        },
        defaults: {
            styles: {
                plot0: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 2,
                    trackPrice: false,
                    transparency: 0,
                    visible: true,
                    color: '#EA7500',
                },
            },
            palettes: {
                smaPalette: {
                    colors: {
                        0: {
                            color: '#E92410',
                            width: 3,
                            style: 0,
                        },
                        1: {
                            color: '#2b7841',
                            width: 3,
                            style: 0,
                        },
                    },
                },
            },
            precision: 2,
            inputs: {
                Param1: 21,
            },
        },
        styles: {
            plot0: {
                title: 'SMA',
                histogramBase: 0,
                joinPoints: false,
            },
        },
        inputs: [
            {
                id: 'Param1',
                name: 'Length',
                defval: 21,
                type: 'integer',
                min: 1,
                max: 1000,
                step: 1,
            },
        ],
    },
});
