import { css } from '@emotion/react';
import { Collapse, ListItem, ListItemText } from '@mui/material';
import { memo } from 'react';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { useAppLocalStorage } from '~/hooks/useAppLocalStorage';
export const CollapseContent = memo(function CollapseContent(props) {
    const [openState, setOpenState, removeOpenState] = useAppLocalStorage(props.localStorageKey, true);
    return (<div className={props.className}>
      <ListItem css={css `
          user-select: none;
          &:hover {
            cursor: pointer;
          }
        `} onClick={() => {
            setOpenState(!openState);
        }}>
        <ListItemText primary={props.displayTitle}/>
        {openState ? <MdExpandLess /> : <MdExpandMore />}
      </ListItem>

      <Collapse in={openState ?? true} unmountOnExit>
        {props.children}
      </Collapse>
    </div>);
});
