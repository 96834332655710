import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const huanan_DualBBand = createIndicator({
    id: 'huanan-DualBBand',
    displayName: 'huanan_DualBBand',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const fastLength = this._input(0);
            const slowLength = this._input(1);
            const stds = this._input(2);
            const close = this.ohlc.closeArray;
            const fastBBand = this.bollingerBand(close, fastLength, stds);
            const slowBBand = this.bollingerBand(close, slowLength, stds);
            return [fastBBand.top, fastBBand.bottom, slowBBand.top, slowBBand.bottom];
        },
    },
    metainfo: {
        is_price_study: true,
        _metainfoVersion: 42,
        isTVScript: false,
        isTVScriptStub: false,
        is_hidden_study: false,
        inputs: [
            {
                id: 'fastLength',
                name: 'Fast Length',
                defval: 60,
                type: 'integer',
                min: 1,
                max: 1000,
                step: 1,
            },
            {
                id: 'slowLength',
                name: 'Slow Length',
                defval: 240,
                type: 'integer',
                min: 1,
                max: 1000,
                step: 1,
            },
            {
                id: 'stds',
                name: 'Standard Deviations',
                defval: 2,
                type: 'float',
                min: 0.1,
                max: 10,
                step: 0.05,
            },
        ],
        plots: [
            { id: 'fastHighBand', type: 'line' },
            { id: 'fastLowBand', type: 'line' },
            { id: 'slowHighBand', type: 'line' },
            { id: 'slowLowBand', type: 'line' },
        ],
        defaults: {
            inputs: {
                fastLength: 60,
                slowLength: 240,
                stds: 2,
            },
            styles: {
                fastHighBand: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 2,
                    trackPrice: false,
                    transparency: 0,
                    visible: true,
                    color: '#E92410',
                },
                fastLowBand: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 2,
                    trackPrice: false,
                    transparency: 0,
                    visible: true,
                    color: '#2b7841',
                },
                slowHighBand: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 2,
                    trackPrice: false,
                    transparency: 0,
                    visible: true,
                    color: '#E92410',
                },
                slowLowBand: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 2,
                    trackPrice: false,
                    transparency: 0,
                    visible: true,
                    color: '#2b7841',
                },
            },
            filledAreasStyle: {
                fastBBandArea: { color: '#D1E38D', transparency: 80, visible: true },
                slowBBandArea: { color: '#AF7EC6', transparency: 80, visible: true },
            },
            precision: 2,
        },
        styles: {
            fastHighBand: {
                title: 'Fast BBand-H',
                histogramBase: 0,
                joinPoints: false,
            },
            fastLowBand: {
                title: 'Fast BBand-L',
                histogramBase: 0,
                joinPoints: false,
            },
            slowHighBand: {
                title: 'Slow BBand-H',
                histogramBase: 0,
                joinPoints: false,
            },
            slowLowBand: {
                title: 'Slow BBand-L',
                histogramBase: 0,
                joinPoints: false,
            },
        },
        filledAreas: [
            {
                id: 'fastBBandArea',
                title: 'fastBBandArea',
                objAId: 'fastHighBand',
                objBId: 'fastLowBand',
                type: 'plot_plot',
            },
            {
                id: 'slowBBandArea',
                title: 'slowBBandArea',
                objAId: 'slowHighBand',
                objBId: 'slowLowBand',
                type: 'plot_plot',
            },
        ],
    },
});
