import { find, forEach } from 'lodash';
import { memo } from 'react';
import { proxy } from 'valtio';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { scrollbarOfBinanceCssFn } from '~/css/scrollbarCss';
import { ChartingModule } from '~/modules/SDK/chart4/ChartingModule';
import { meCheckHandlerCreateByProductNameWithExpiredAt } from '~/modules/SDK/me/meCheckHandlerCreateByProductName';
import { DefaultsInformation } from '~/pages/david0705/trading_analyzer/DefaultsInformation';
import { huanan_day_range } from '~/trades/indicators/defaults/huanan_day_range';
import { huanan_day_range_5 } from '~/trades/indicators/defaults/huanan_day_range_5';
import { dealer_opt } from '~/trades/indicators/futuresai/futures_chips/dealer_opt';
import { dealer_tpex } from '~/trades/indicators/futuresai/futures_chips/dealer_tpex';
import { dealer_twse } from '~/trades/indicators/futuresai/futures_chips/dealer_twse';
import { dealer_txf } from '~/trades/indicators/futuresai/futures_chips/dealer_txf';
import { foreigner_opt } from '~/trades/indicators/futuresai/futures_chips/foreigner_opt';
import { foreigner_tpex } from '~/trades/indicators/futuresai/futures_chips/foreigner_tpex';
import { foreigner_twse } from '~/trades/indicators/futuresai/futures_chips/foreigner_twse';
import { foreigner_txf } from '~/trades/indicators/futuresai/futures_chips/foreigner_txf';
import { large_trader_futures_last5 } from '~/trades/indicators/futuresai/futures_chips/large_trader_futures_last5';
import { large_trader_futures_top10 } from '~/trades/indicators/futuresai/futures_chips/large_trader_futures_top10';
import { large_trader_futures_top5 } from '~/trades/indicators/futuresai/futures_chips/large_trader_futures_top5';
import { large_trader_options_last5 } from '~/trades/indicators/futuresai/futures_chips/large_trader_options_last5';
import { large_trader_options_top10 } from '~/trades/indicators/futuresai/futures_chips/large_trader_options_top10';
import { large_trader_options_top5 } from '~/trades/indicators/futuresai/futures_chips/large_trader_options_top5';
import { retail_oi } from '~/trades/indicators/futuresai/futures_chips/retail_oi';
import { settlement_line } from '~/trades/indicators/futuresai/futures_chips/settlement_line';
import { trust_tpex } from '~/trades/indicators/futuresai/futures_chips/trust_tpex';
import { trust_twse } from '~/trades/indicators/futuresai/futures_chips/trust_twse';
import { dealer_mxf } from '~/trades/indicators/futuresai/futures_chips/dealer_mxf';
import { foreigner_mxf } from '~/trades/indicators/futuresai/futures_chips/foreigner_mxf';
import { adForTVChart2 } from '~/trades/indicators/futuresai/ad';
import { bs0ForTVChart2 } from '~/trades/indicators/futuresai/bs0';
import { bs1ForTVChart2 } from '~/trades/indicators/futuresai/bs1';
import { bs2ForTVChart2 } from '~/trades/indicators/futuresai/bs2';
import { dc } from '~/trades/indicators/futuresai/dc';
import { extremeVolume } from '~/trades/indicators/futuresai/extreme_volume';
import { extremeVolumeHuanan } from '~/trades/indicators/futuresai/extreme_volume_huanan';
import { estimatedVolumeForTVChart2 } from '~/trades/indicators/futuresai/estimated_volume';
import { ivForTVChart2 } from '~/trades/indicators/futuresai/iv';
import { opmfForTVChart2 } from '~/trades/indicators/futuresai/opmf';
import { opofForTVChart2 } from '~/trades/indicators/futuresai/opof';
import { subtractForTVChart2 } from '~/trades/indicators/futuresai/subtract';
import { tiForTVChart2 } from '~/trades/indicators/futuresai/ti';
import { timeValueForTVChart2 } from '~/trades/indicators/futuresai/time_value';
import { fr_agents } from '~/pages/heineken_template/_fr/fr_agents';
import { T3j_SidePane1 } from '~/pages/t3j/t3j_SidePane1';
import { huanan_ColoredMovAvg } from '~/trades/indicators/huanan/huanan_ColoredMovAvg';
import { huanan_DualBBand } from '~/trades/indicators/huanan/huanan_DualBBand';
import { FuiTabs2 } from '~/pages/heineken_template/components/FuiTabs2';
import { eurex_main_trend } from '~/trades/indicators/eurex/eurex_main_trend';
import { huanan_DailyRange } from '~/trades/indicators/huanan/huanan_DailyRange';
// 台指期籌碼   ( 主散  攻擊力道  掛單 )
// 選擇權分析   ( 選擇權金劉  訂單劉   隱波  時間價值)
// 全部  (1+2+漲跌家)
// 無指標
/**
 * ## huanan_template 的全局預設 Config as Store
 *
 * - 可被覆蓋
 */
export const huanan_store = proxy({
    /**
     * ## 預設載入好的額外指標
     *
     * - 若指標存在於 StrategyConfig 內的話，則放不放於此都沒關係
     */
    chartingPreloadedIndicators: [
        bs0ForTVChart2,
        bs1ForTVChart2,
        bs2ForTVChart2,
        opmfForTVChart2,
        opofForTVChart2,
        tiForTVChart2,
        subtractForTVChart2,
        adForTVChart2,
        ivForTVChart2,
        timeValueForTVChart2,
        dc,
        extremeVolume,
        huanan_day_range,
        huanan_day_range_5,
        extremeVolumeHuanan,
        estimatedVolumeForTVChart2,
        // daily-chips 用到的
        settlement_line,
        foreigner_txf,
        foreigner_twse,
        foreigner_tpex,
        foreigner_opt,
        foreigner_mxf,
        dealer_twse,
        dealer_tpex,
        dealer_txf,
        dealer_mxf,
        dealer_opt,
        trust_tpex,
        trust_twse,
        retail_oi,
        large_trader_futures_top5,
        large_trader_futures_top10,
        large_trader_futures_last5,
        large_trader_options_top5,
        large_trader_options_top10,
        large_trader_options_last5,
    ],
    charting: proxy(new ChartingModule()),
    pages: {
        dailyChips: {
            tabs: new FuiTabs2(['籌碼概覽', '留倉籌碼', '按期貨契約累積籌碼']),
        },
        all: {
            canAccess: meCheckHandlerCreateByProductNameWithExpiredAt('web'),
        },
        quote: {
            strategyConfigs: [
                {
                    displayName: '趨勢指標',
                    indicators: [
                        huanan_day_range,
                        huanan_day_range_5,
                        huanan_ColoredMovAvg,
                        huanan_DualBBand,
                        eurex_main_trend,
                        huanan_DailyRange,
                    ],
                    interval: 5,
                    symbol: 'TWN-1',
                    panesRatio: 70,
                },
                {
                    displayName: '移除指標',
                    indicators: [],
                    interval: 5,
                    symbol: 'TWN-1',
                    panesRatio: 100,
                },
            ],
        },
    },
    sidebarItems: [
        {
            type: 'notable',
            title: '即時指標',
            url: '/quote',
            plugins: {
                insidePane: memo(function InsidePane(props) {
                    return <DefaultsInformation css={scrollbarOfBinanceCssFn}/>;
                }),
            },
        },
        //
        // { type: 'notable', title: '即時排行', url: '/ranking-realtime' },
        // { type: 'notable', title: '權值排行', url: '/ranking' },
        { type: 'notable', title: '大盤籌碼', url: '/daily-chips' },
        // { type: 'notable', title: '個股篩選', url: '/screener' },
        //
        { type: 'notable', title: '交易健檢', url: '/trading_analyzer' },
        { type: 'notable', title: '當日成交量', url: '/volume-view' },
        { type: 'notable', title: '當日振幅', url: '/amplitude-view' },
        { type: 'notable', title: '漲跌振幅分佈', url: '/kbar-statistics' },
        /*{ type: 'notable', title: '走勢軌跡分析', url: '/historical-chart' },
        { type: 'notable', title: '波動率統計', url: '/vix-statistics' },*/
    ],
    activateSidebarItem(target) {
        forEach(huanan_store.sidebarItems, item => {
            item.active = false;
        });
        const found = find(huanan_store.sidebarItems, item => {
            return item.url === target.url;
        });
        if (found)
            found.active = true;
    },
});
huanan_store.charting.lightOverrides = {
    'scalesProperties.textColor': '#555',
    'mainSeriesProperties.candleStyle.upColor': '#d75442',
    'mainSeriesProperties.candleStyle.downColor': '#6ba583',
    'mainSeriesProperties.candleStyle.borderUpColor': '#5b1a13',
    'mainSeriesProperties.candleStyle.borderDownColor': '#225437',
    'mainSeriesProperties.candleStyle.wickUpColor': 'rgba( 115, 115, 117, 1)',
    'mainSeriesProperties.candleStyle.wickDownColor': 'rgba( 115, 115, 117, 1)',
    'paneProperties.background': '#fff',
    'paneProperties.vertGridProperties.color': '#E6E6E6',
    'paneProperties.vertGridProperties.style': 1,
    'paneProperties.horzGridProperties.color': '#E6E6E6',
    'paneProperties.horzGridProperties.style': 1,
    'mainSeriesProperties.candleStyle.drawBorder': false,
    'paneProperties.topMargin': 15,
    'paneProperties.bottomMargin': 25,
    'timeScale.rightOffset': 20,
};
huanan_store.charting.darkOverrides = {
    'scalesProperties.textColor': '#ffffff',
    'mainSeriesProperties.candleStyle.upColor': '#d75442',
    'mainSeriesProperties.candleStyle.downColor': '#6ba583',
    'mainSeriesProperties.candleStyle.borderUpColor': '#5b1a13',
    'mainSeriesProperties.candleStyle.borderDownColor': '#225437',
    'mainSeriesProperties.candleStyle.wickUpColor': 'rgba( 115, 115, 117, 1)',
    'mainSeriesProperties.candleStyle.wickDownColor': 'rgba( 115, 115, 117, 1)',
    'paneProperties.background': '#fff',
    'paneProperties.vertGridProperties.color': '#313131',
    'paneProperties.vertGridProperties.style': 1,
    'paneProperties.horzGridProperties.color': '#313131',
    'paneProperties.horzGridProperties.style': 1,
    'mainSeriesProperties.candleStyle.drawBorder': false,
    'paneProperties.topMargin': 15,
    'paneProperties.bottomMargin': 25,
    'timeScale.rightOffset': 20,
};
if (fr_agents.is['t3j@web']) {
    useThemeStore.setState({ theme: 'dark' });
    huanan_store.charting.setThemeMode('dark');
    huanan_store.charting.indicatorsPreloaded = [];
    huanan_store.charting.indicators = [];
    huanan_store.charting.widgetOptions = {
        ...huanan_store.charting.widgetOptions,
        symbol: 'BTCUSDT',
        interval: '5',
    };
    huanan_store.charting.initStrategy({
        configs: [
            {
                displayName: '全部指標',
                indicators: [
                    /*bs0ForTVChart2,
                    bs1ForTVChart2,
                    bs2ForTVChart2,
                    opmfForTVChart2,
                    opofForTVChart2,
                    tiForTVChart2,
                    subtractForTVChart2,
                    adForTVChart2,
                    ivForTVChart2,
                    timeValueForTVChart2,*/
                    dc,
                    /*extremeVolume,
                    huanan_day_range,
                    huanan_day_range_5,
                    extremeVolumeHuanan,
                    estimatedVolumeForTVChart2,*/
                ],
            },
        ],
    });
    huanan_store.sidebarItems = [
        {
            type: 'notable',
            title: '即時指標',
            url: '/quote',
            plugins: {
                insidePane: memo(function InsidePane(props) {
                    return <T3j_SidePane1 css={scrollbarOfBinanceCssFn}/>;
                }),
            },
        },
        //
        // { type: 'notable', title: '即時排行', url: '/ranking-realtime' },
        // { type: 'notable', title: '權值排行', url: '/ranking' },
        // { type: 'notable', title: '大盤籌碼', url: '/daily-chips' },
        // { type: 'notable', title: '個股篩選', url: '/screener' },
        //
        // { type: 'notable', title: '程式策略', url: '/strategy' },
        // { type: 'notable', title: '交易健檢', url: '/trading_analyzer' },
        { type: 'notable', title: '當日成交量', url: '/volume-view' },
        { type: 'notable', title: '當日振幅', url: '/amplitude-view' },
        { type: 'notable', title: '漲跌振幅分佈', url: '/kbar-statistics' },
        { type: 'notable', title: '走勢軌跡分析', url: '/crypto-historical-chart' },
        // { type: 'notable', title: '波動率統計', url: '/vix-statistics' },
    ];
}
if (fr_agents.is['huanan@web']) {
    useThemeStore.setState({ theme: 'dark' });
    huanan_store.charting.setThemeMode('dark');
    huanan_store.charting.indicatorsPreloaded = huanan_store.chartingPreloadedIndicators;
    huanan_store.charting.widgetOptions = {
        enableVolumeIndicator: true,
        symbol: 'TWN-1',
        interval: 5,
    };
    huanan_store.charting.initStrategy({
        configs: huanan_store.pages.quote.strategyConfigs,
    });
}
